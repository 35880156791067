import { EntityStatus } from '../../../../../../app/core/models/entity-status';
import { DESTINATION_TYPES } from '../../../../../../app/nodes/destination-type/model';
import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import { ModelStatusEnum } from '../../../../transform-model/model-status';
import { showPaidSourceBadge } from '../../../../pipeline/create/SelectSourceType/SelectSourceList/util';
import { GlobalSearchEntity } from '../../GlobalSearchReducer';
import {
  GlobalSearchDocLinksResponse,
  GlobalSearchEntityData,
  GlobalSearchEntityDestinationData,
  GlobalSearchEntityDestinationTypeData,
  GlobalSearchEntityModelData,
  GlobalSearchEntityPipelineData,
  GlobalSearchEntitySourceTypeData
} from '../../models';
import { CreateNewEntityExcerptData } from './models';

export function getEntityStatus(entity: any): EntityStatus {
  let status: EntityStatus;

  switch (entity.entityType) {
    case GlobalSearchEntity.PIPELINES:
      status = {
        name: entity.pipeline_status.display_name,
        colorType: entity.pipeline_status.color_type,
        value: entity.pipeline_status.status
      };
      break;

    case GlobalSearchEntity.MODELS:
      status = ModelStatusEnum[entity.display_status.name];
      break;

    case GlobalSearchEntity.DESTINATIONS:
    case GlobalSearchEntity.ACTIVATIONS:
    case GlobalSearchEntity.WORKFLOWS:
    case GlobalSearchEntity.TARGETS:
      status = entity.status as EntityStatus;
      break;

    default:
      break;
  }

  return status;
}

export function getNewEntityData(
  entity: GlobalSearchEntityData,
  isFreePlanCustomer: boolean
): CreateNewEntityExcerptData | undefined {
  const { entityType } = entity;
  let data: CreateNewEntityExcerptData;

  switch (entityType) {
    case GlobalSearchEntity.DESTINATION_TYPES: {
      const destinationEntity = entity as GlobalSearchEntityDestinationTypeData;
      const destinationMetaData = DESTINATION_TYPES[destinationEntity.dest_type];

      data = {
        id: `${destinationMetaData.name}-DESTINATION`,
        docLink: destinationMetaData.docLink ? destinationMetaData.docLink : undefined,
        cta: 'Create Destination',
        icon: 'destinations',
        displayMeta: {
          logoURL: destinationEntity.dest_type_logo_url,
          darkModeLogoURL:
            destinationEntity?.dest_type_dark_mode_logo_url || destinationEntity.dest_type_logo_url,
          primaryColor: destinationMetaData?.primaryColor,
          darkModePrimaryColor: destinationMetaData?.darkModePrimaryColor,
          name: destinationMetaData?.name || destinationEntity.dest_type_display,
          displayName: destinationEntity.dest_type_display
        }
      };

      break;
    }

    case GlobalSearchEntity.SOURCE_TYPES: {
      const sourceType = entity as GlobalSearchEntitySourceTypeData;
      const sourceTypeMetaData = SOURCE_TYPES[sourceType.source_type];

      data = {
        id: `${sourceTypeMetaData?.name || sourceType.source_type}-SOURCE`,
        docLink:
          sourceTypeMetaData?.docLink && sourceTypeMetaData.docLink !== '/sources/'
            ? sourceTypeMetaData.docLink
            : undefined,
        cta: 'Create Pipeline',
        icon: 'pipeline',
        isDisabled: sourceType?.is_disabled || false,
        displayMeta: {
          logoURL: sourceType.source_type_logo_url,
          darkModeLogoURL:
            sourceType?.source_type_dark_mode_logo_url || sourceType.source_type_logo_url,
          primaryColor: sourceTypeMetaData?.primaryColor || sourceType?.source_type_colour_code,
          darkModePrimaryColor: sourceTypeMetaData?.darkModePrimaryColor,
          name: sourceTypeMetaData?.name || sourceType.source_type,
          displayName: sourceType.source_type_display,
          showUpcomingBadge: !!sourceType?.is_upcoming,
          showPaidBadge: showPaidSourceBadge(sourceType, isFreePlanCustomer)
        }
      };

      break;
    }

    default:
      break;
  }

  return data;
}

export function getDocLinks(
  entity: GlobalSearchEntityData,
  docLinksMap: GlobalSearchDocLinksResponse
) {
  const { entityType } = entity;
  const docLinks = [...docLinksMap[entityType]];

  if (docLinks.length === 0) {
    return [];
  }

  switch (entityType) {
    case GlobalSearchEntity.PIPELINES: {
      const sourceDocLink =
        SOURCE_TYPES[(entity as GlobalSearchEntityPipelineData).source_type]?.docLink;
      const destinationDocLink =
        DESTINATION_TYPES[(entity as GlobalSearchEntityPipelineData).dest_type]?.docLink;

      if (sourceDocLink) {
        docLinks.push({
          title: `${(entity as GlobalSearchEntityPipelineData).source_type_display} Source`,
          url: sourceDocLink
        });
      }

      if (destinationDocLink) {
        docLinks.push({
          title: `${(entity as GlobalSearchEntityPipelineData).dest_type_display} Destination`,
          url: destinationDocLink
        });
      }

      break;
    }

    case GlobalSearchEntity.MODELS: {
      const modelDocLink =
        DESTINATION_TYPES[(entity as GlobalSearchEntityModelData).destination.dest_type]?.docLink;

      if (modelDocLink) {
        docLinks.push({
          title: `${
            (entity as GlobalSearchEntityModelData).destination.dest_type_display
          } Destination`,
          url: modelDocLink
        });
      }

      break;
    }

    case GlobalSearchEntity.DESTINATIONS: {
      const docLink =
        DESTINATION_TYPES[(entity as GlobalSearchEntityDestinationData).dest_type]?.docLink;

      if (docLink) {
        docLinks.push({
          title: `${(entity as GlobalSearchEntityDestinationData).dest_type_display} Destination`,
          url: docLink
        });
      }

      break;
    }

    default:
      break;
  }

  if (docLinks.length > 4) {
    const firstCol = docLinks.slice(0, 4);
    const secondCol = docLinks.slice(4, docLinks.length);

    return [firstCol, secondCol];
  }

  return [docLinks];
}
