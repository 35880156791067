import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'hd-create-entity-redirection',
  template: ''
})
export class CreateEntityRedirection {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    const entity = this._route.snapshot.paramMap.get('entity');
    let commands = ['pipeline', 'create'];

    if (entity === 'destination') {
      commands = ['destination', 'add'];
    }

    this._router.navigate(
      commands,
      {
        queryParams: {
          wizard_step: null
        },
        replaceUrl: true
      });
  }
}
