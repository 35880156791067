import { SOURCE_TYPES } from '../../../../../../app/nodes/source-type/source-type';
import { RawSourceType } from '../models';

export function showFreeBadge(sourceType: RawSourceType, isFreePlanCustomer: boolean): boolean {
  if (!isFreePlanCustomer || showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return (
    sourceType.is_free &&
    (sourceType?.days_left_in_trial === undefined || sourceType.days_left_in_trial <= 0)
  );
}

export function showPaidSourceBadge(
  sourceType: RawSourceType,
  isFreePlanCustomer: boolean
): boolean {
  if (!isFreePlanCustomer || showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return (
    !sourceType.is_free &&
    (sourceType?.days_left_in_trial === undefined || sourceType?.days_left_in_trial <= 0)
  );
}

export function showOngoingTrialBadge(sourceType: RawSourceType) {
  if (showEarlyAccessBadge(sourceType)) {
    return false;
  }

  return !sourceType.is_disabled && !sourceType.is_unused && sourceType?.days_left_in_trial > 0;
}

export function showBetaBadge(sourceType: RawSourceType) {
  return SOURCE_TYPES[sourceType.source_type]?.beta;
}

export function showEarlyAccessBadge(sourceType: RawSourceType) {
  return SOURCE_TYPES[sourceType.source_type]?.earlyAccess;
}
