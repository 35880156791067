import { Box, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { HevoEntity } from '../../../../../../app/core/models/hevo-entity';
import AdvancedConfig from '../../../../../components/AdvancedConfigWrapper';
import { HdFormikDropDown } from '../../../../../components/FormikElements';
import { GoogleAuth } from '../../../../../components/GoogleAuthFormik';
import { LoadedAt, SanitizeName } from '../../../../../components/NodeConfigOptions';
import { HdIcon, HdLink, HdTooltip } from '../../../../../components/UIElements';
import HdFormControl from '../../../../../components/UIElements/HdFormControl';
import HdSetupGuideLink from '../../../../../components/UIElements/HdSetupGuideLink';
import {
  HdTab,
  HdTabHead,
  HdTabLabel,
  HdTabPanel
} from '../../../../../components/UIElements/HdTabs';
import useAnalyticsTracker from '../../../../../hooks/useAnalyticsTracker';
import useDontMountAtFirst from '../../../../../hooks/useDontMountAtFirst';
import useTeamSettingsService from '../../../../../hooks/services/useTeamSettingsService';
import { getDataIdGenerator } from '../../../../../utils/generateDataId';
import { ConfigDestinationTypeBaseProps } from '../interface';
import BigQueryEdit from './bigQueryEdit';
import { AUTH_FORM_FIELD_NAME, BigqueryResourceType } from './model';
import { StreamingWritesConfig } from './StreamingWritesConfig';
import styles from './styles.module.scss';
import { ResourceTab } from './tabContainer';
import { BigqueryDestinationTrackingActions } from './tracking';
import {
  checkForUserPermission,
  fetchProjectList,
  fetchResourceListFn,
  getBigQueryAuthAccountInfo,
  getBucketName,
  getDatasetName
} from './utils';

export default function BigQuery(props: ConfigDestinationTypeBaseProps) {
  const { isEditing, hevoEntityFor } = props;

  if (isEditing || hevoEntityFor === HevoEntity.ACTIVATION || hevoEntityFor === HevoEntity.MODEL) {
    return <BigQueryEdit {...props} />;
  }

  return <BigQueryCreate {...props} />;
}

export const bucketErrorMessage = () => (
  <div>
    Bucket Name must:
    <ul className='pl-3'>
      <li>Contain only lowercase letters, numbers, dashes, underscores and dots</li>
      <li>Start and end with a number or letter</li>
      <li>Contain 3 - 63 characters</li>
    </ul>
  </div>
);

export const datasetErrorMessage = () => (
  <div>
    Dataset Name must:
    <ul className='pl-3'>
      <li>Contain up to 1024 characters</li>
      <li>Contain only letters(upper or lower case), numbers, and underscores</li>
    </ul>
  </div>
);

function RefreshProjectIDDropdownAdornment({ refreshProjectList }) {
  const { eventTrack } = useAnalyticsTracker();

  return (
    <HdLink
      className={`${styles.refreshAdornment} text-primary `}
      tag='a'
      onMouseDown={() => {
        refreshProjectList();
        eventTrack({
          action: BigqueryDestinationTrackingActions.PROJECT_REFRESH_LIST_CLICK
        });
      }}
      direction='right'
      icon='refresh'
      dataId='bigquery-project-refresh'
    >
      Refresh
    </HdLink>
  );
}

export function BigQueryCreate(props: ConfigDestinationTypeBaseProps) {
  const { formMetaData, hevoEntityFor, destinationTypeIdentifier } = props;

  const { getTeamSettings } = useTeamSettingsService();

  const { values, isSubmitting, setValues } = useFormikContext<{
    auth?: any;
    projectId: { id: string };
    authorisedAccount: string;
    streamingWrites: boolean;
  }>();

  const { projects = [], buckets = [], datasets = [], clusterRegion } = formMetaData;
  const isStreamModeAllowed = getTeamSettings()?.wh_streaming_writes === true;
  const useStreamMode = isStreamModeAllowed && values.streamingWrites;
  const [projectList, setProjectList] = useState(projects);

  const { eventTrack } = useAnalyticsTracker();

  const [showProjectLoading, setProjectLoading] = useState(false);
  const [showNoProjectError, setNoProjectError] = useState(!projectList.length && !!values.auth);

  const destinationTypeTrackingProps = {
    destinationType: destinationTypeIdentifier,
    context: hevoEntityFor
  };

  const refreshProjectList = () => {
    const { type, id } = getBigQueryAuthAccountInfo(values, AUTH_FORM_FIELD_NAME);
    setProjectLoading(true);
    setProjectList([]);
    setNoProjectError(false);

    fetchProjectList(type, id)
      .then(response => {
        setProjectLoading(false);
        setProjectList(response);

        if (!response.length) {
          eventTrack({
            action: BigqueryDestinationTrackingActions.PROJECT_LIST_EMPTY
          });
          setNoProjectError(true);
        }
      })
      .catch(_ => {
        setProjectLoading(false);
        setProjectList([]);
      });
  };

  const handleProjectChange = project => {
    setValues(prevValues => ({
      ...prevValues,
      projectId: project,
      bucket: null,
      dataset: null
    }));
  };

  useDontMountAtFirst(() => {
    handleProjectChange(null);
    setProjectList([]);
    refreshProjectList();
  }, [values.auth?.id]);

  const dataIdGenerator = getDataIdGenerator('bigquery-create');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <HdFormControl>
            <Field
              name={AUTH_FORM_FIELD_NAME}
              provider='BIGQUERY'
              displayName='Big Query'
              hevoEntity={hevoEntityFor}
              component={GoogleAuth}
            />
          </HdFormControl>
        </Grid>

        <Grid item md={12} sm={12}>
          <HdFormControl>
            <Field
              required
              label='Project ID'
              name='projectId'
              showLoading={showProjectLoading}
              TopAdornment={RefreshProjectIDDropdownAdornment}
              options={projectList}
              topAdornmentProps={{
                refreshProjectList
              }}
              onChangeEventHandler={handleProjectChange}
              noOptionsText='No Projects found in your account.'
              helperText={
                showNoProjectError ? (
                  <Typography variant='caption' className={styles.errorCaption}>
                    Your Bigquery account does not have a Google Project. Please create one and try
                    again.
                  </Typography>
                ) : (
                  <Typography variant='caption'>
                    Select the Project of your BigQuery instance.{' '}
                    <HdSetupGuideLink
                      setupGuideLink='/destinations/data-warehouses/google-bigquery/#configure-google-bigquery-as-a-destination'
                      section='destination-settings'
                      label='project-create-help-text'
                      properties={destinationTypeTrackingProps}
                      size='sm'
                      icon='right'
                      dataId={dataIdGenerator('project')}
                    >
                      Learn More
                    </HdSetupGuideLink>
                  </Typography>
                )
              }
              component={HdFormikDropDown}
            />
          </HdFormControl>
        </Grid>
      </Grid>

      <div className='border border-radius-md mb-7 w-100'>
        <HdTab>
          <HdTabHead>
            <HdTabLabel value={0}>
              Dataset{' '}
              <HdTooltip title='Hevo will load data to this Dataset'>
                <div>
                  <HdIcon className='ml-1' name='info' />
                </div>
              </HdTooltip>
            </HdTabLabel>

            {!useStreamMode && (
              <HdTabLabel value={1}>
                GCS Bucket{' '}
                <HdTooltip title='Hevo uses a GCS bucket to load data to BigQuery'>
                  <div>
                    <HdIcon className='ml-1' name='info' />
                  </div>
                </HdTooltip>
              </HdTabLabel>
            )}
          </HdTabHead>

          <Box>
            <HdTabPanel key='dataset' forceOpen={isSubmitting} value={0} keepMounted>
              <ResourceTab
                label='Dataset'
                defaultResourceList={datasets}
                defaultLocation={clusterRegion}
                resource={BigqueryResourceType.DATASET}
                autoCreateResourceName={getDatasetName}
                checkIfUserHasCreatePermission={checkForUserPermission}
                fetchResourceList={fetchResourceListFn}
                dataId={dataIdGenerator(BigqueryResourceType.DATASET)}
              />
            </HdTabPanel>

            {!useStreamMode && (
              <HdTabPanel key='bucket' forceOpen={isSubmitting} value={1} keepMounted>
                <ResourceTab
                  label='GCS bucket'
                  defaultResourceList={buckets}
                  defaultLocation={clusterRegion}
                  resource={BigqueryResourceType.BUCKET}
                  autoCreateResourceName={getBucketName}
                  checkIfUserHasCreatePermission={checkForUserPermission}
                  fetchResourceList={fetchResourceListFn}
                  dataId={dataIdGenerator(BigqueryResourceType.BUCKET)}
                />
              </HdTabPanel>
            )}
          </Box>
        </HdTab>
      </div>

      <AdvancedConfig showBorderBottom>
        <div className='w-100'>
          <LoadedAt />

          <SanitizeName
            dataId={dataIdGenerator('sanitize-name')}
          />

          {isStreamModeAllowed ? (
            <StreamingWritesConfig dataId={dataIdGenerator('')} />
          ) : null}
        </div>
      </AdvancedConfig>
    </>
  );
}
