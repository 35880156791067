import { ActivationsFactory } from '../../../../app/activate/utils';
import { BACKGROUND_NETWORK_REQ_OPTIONS } from '../../../../app/core/constants';
import { courier } from '../../../../app/core/service/courier.service';
import { ModelStatusEnum } from '../../transform-model/model-status';
import { TargetsFactory } from '../../activate-target/utils';
import { WorkflowStatusEnum } from '../../workflow/models/workflow-status';
import ActivateTargetAPI from '../../activate-target/ActivateTargetAPI';
import ActivationsAPI from '../../activate/ActivationsAPI';
import { RawDestinationType } from '../../destination/create/SelectDestinationType/models';
import DestinationsAPI from '../../destination/DestinationsAPI';
import { RawSourceType } from '../../pipeline/create/SelectSourceType/models';
import PipelinesAPI from '../../pipeline/PipelinesAPI';
import TransformModelAPI from '../../transform-model/TransformModelAPI';
import WorkflowAPI from '../../workflow/WorkflowAPI';
import { AlgoliaSearchFactory } from './AlgoliaSearch';
import { GlobalSearchEntity, GlobalSearchReducerActions } from './GlobalSearchReducer';

// eslint-disable-next-line no-unused-vars
declare type GlobalSearchAPIFn = (actions: GlobalSearchReducerActions) => void;
// eslint-disable-next-line no-unused-vars
declare type AlgoliaSearchFn = (
  search: string,
  actions: GlobalSearchReducerActions,
  isWorkFlowsEnabled: boolean,
  isActivationEnabled: boolean
) => void;
declare type EntitiesStaticDocsFn<T = any> = () => Promise<T>;

function EntityFactory(data) {
  return {
    entities: data
  };
}

interface IGlobalSearchAPI {
  fetchEntitiesStaticDocs: EntitiesStaticDocsFn;
  fetchModels: GlobalSearchAPIFn;
  fetchPipelines: GlobalSearchAPIFn;
  fetchDestinations: GlobalSearchAPIFn;
  fetchWorkflows: GlobalSearchAPIFn;
  fetchTargets: GlobalSearchAPIFn;
  fetchActivations: GlobalSearchAPIFn;
  fetchSourceTypes: GlobalSearchAPIFn;
  fetchDestinationTypes: GlobalSearchAPIFn;
  fetchAlgoliaResults: AlgoliaSearchFn;
}

export const GlobalSearchAPI: IGlobalSearchAPI = {
  fetchActivations(actions) {
    actions.getEntityData(GlobalSearchEntity.ACTIVATIONS);

    ActivationsAPI.getActivations()
      .then(res => {
        const activations = ActivationsFactory(res.data);
        actions.updateEntityData(GlobalSearchEntity.ACTIVATIONS, EntityFactory(activations));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.ACTIVATIONS);
      });
  },

  fetchAlgoliaResults(search, actions, isWorkFlowsEnabled, isActivationEnabled) {
    if (!search) {
      return;
    }

    actions.getEntityData(GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS);

    AlgoliaSearchFactory(search, isWorkFlowsEnabled, isActivationEnabled)
      .then(res => {
        actions.updateEntityData(GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS, EntityFactory(res));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.ALGOLIA_SEARCH_RESULTS);
      });
  },

  fetchDestinationTypes(actions) {
    actions.getEntityData(GlobalSearchEntity.DESTINATION_TYPES);

    DestinationsAPI.getDestinationTypes(false)
      .then(res => {
        const destinationTypes = res.data.map((destinationType: RawDestinationType) => ({
          ...destinationType,
          id: destinationType.dest_type
        }));

        actions.updateEntityData(
          GlobalSearchEntity.DESTINATION_TYPES,
          EntityFactory(destinationTypes)
        );
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.DESTINATION_TYPES);
      });
  },

  fetchDestinations(actions) {
    actions.getEntityData(GlobalSearchEntity.DESTINATIONS);

    DestinationsAPI.getExistingDestinations()
      .then(res => {
        actions.updateEntityData(GlobalSearchEntity.DESTINATIONS, EntityFactory(res.data));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.DESTINATIONS);
      });
  },

  fetchModels(actions) {
    actions.getEntityData(GlobalSearchEntity.MODELS);

    TransformModelAPI.getModels()
      .then(res => {
        const nonDraftModels = res.data.filter(
          model => model?.status !== ModelStatusEnum.DRAFT.value
        );

        actions.updateEntityData(GlobalSearchEntity.MODELS, EntityFactory(nonDraftModels));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.MODELS);
      });
  },

  fetchPipelines(actions) {
    actions.getEntityData(GlobalSearchEntity.PIPELINES);

    PipelinesAPI.getIntegrations(undefined, false)
      .then(res => {
        actions.updateEntityData(GlobalSearchEntity.PIPELINES, EntityFactory(res.data));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.PIPELINES);
      });
  },

  fetchSourceTypes(actions) {
    actions.getEntityData(GlobalSearchEntity.SOURCE_TYPES);

    PipelinesAPI.getSourceTypes(false)
      .then(res => {
        const sourceTypes = res.data.map((sourceType: RawSourceType) => ({
          ...sourceType,
          id: sourceType.source_type
        }));

        actions.updateEntityData(GlobalSearchEntity.SOURCE_TYPES, EntityFactory(sourceTypes));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.SOURCE_TYPES);
      });
  },

  fetchTargets(actions) {
    actions.getEntityData(GlobalSearchEntity.TARGETS);

    ActivateTargetAPI.getTargets()
      .then(res => {
        const targets = TargetsFactory(res.data);

        actions.updateEntityData(GlobalSearchEntity.TARGETS, EntityFactory(targets));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.TARGETS);
      });
  },

  fetchWorkflows(actions) {
    actions.getEntityData(GlobalSearchEntity.WORKFLOWS);

    WorkflowAPI.getWorkflows(false)
      .then(res => {
        const nonDraftWorkflows = res.filter(
          workflow => workflow.status.value !== WorkflowStatusEnum.DRAFT.value
        );

        actions.updateEntityData(GlobalSearchEntity.WORKFLOWS, EntityFactory(nonDraftWorkflows));
      })
      .catch(() => {
        actions.apiError(GlobalSearchEntity.WORKFLOWS);
      });
  },

  fetchEntitiesStaticDocs() {
    const requestUrl =
      'https://res.cloudinary.com/hevo/raw/upload/v1675433536/dashboard/json/docs/docs_te6dgt.json';

    return courier.get(requestUrl, BACKGROUND_NETWORK_REQ_OPTIONS);
  }
};
